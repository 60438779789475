import * as React from "react";
import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import * as Validator from "../../utils/validation";
import Link from "../../parts/Link";
import { AUTH_API_PATH } from "./apiPath";
import "./Auth.css";

export default function SignIn() {
  const [isValidEmail, setValidEmail] = useState(true);
  const [isValidName, setValidName] = useState(true);
  const [isValidPassword, setValidPassword] = useState(true);

  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      navigate("/cars");
    }
  }, [user]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const {
      target: { email: emailValue, password: passWordValue, name: nameValue, role: roleValue },
    } = event;

    const email = emailValue.value;
    const password = passWordValue.value;
    const name = nameValue.value;
    const role = 0;

    if (isValidEmail && isValidPassword && isValidName) {
      axios
        .post(AUTH_API_PATH + "/register", {
          email,
          name,
          password,
          role,
        })
        .then(() => {
          navigate("/login");
          toast.success("Success !");
        })
        .catch(() => {
          toast.error("Error !");
        });
    }
  };

  const checkValidEmail = ({ target }) =>
    setValidEmail(Validator.isValidEmail(target.value));
  const checkValidPassword = ({ target }) =>
    setValidPassword(Validator.lengthMoreThan(target.value, 7));
  const checkValidName = ({ target }) =>
    setValidName(Validator.lengthMoreThan(target.value, 3));

  return (
    <>
      <div className="parentDiv">
        <img
          className="authIcon"
          src="/images/logo.png"
        ></img>
        <div className="childDiv">
          <Container component="main" maxWidth="xs">
            <Box className="parentForm">
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="E-mail"
                  name="email"
                  autoComplete="email"
                  error={!isValidEmail}
                  onBlur={(e) => checkValidEmail(e)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  error={!isValidName}
                  onBlur={(e) => checkValidName(e)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={!isValidPassword}
                  onBlur={(event) => checkValidPassword(event)}
                />
                <Box className="loginLinkDiv">
                  <Link to="/login" text="Login" />
                </Box>
                <Button
                  className="registerButton"
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                >
                  Register
                </Button>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
