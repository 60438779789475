import api from "../utils/api";
import { toast } from "react-toastify";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Button } from "@mui/material";

const DeleteRow = (props) => {
  const onDelete = (event) => {
    event.stopPropagation();
    api
      .delete(`${props.link}/${props.id}`)
      .then(() => {
        props.afterDelete();
        toast.success("Success!");
      })
      .catch((e) => {
        toast.error(e.response.data.error);
      });
  };

  return (
    <Button onClick={onDelete} color="error">
      <span>
        <DeleteForeverOutlinedIcon />
      </span>
    </Button>
  );
};

export default DeleteRow;
