import * as React from "react";
import { useContext, useState } from "react";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import "./Menu.css";
import AppsIcon from '@mui/icons-material/Apps';
import LogoutIcon from "@mui/icons-material/Logout";
import PostAddIcon from "@mui/icons-material/PostAdd";
import LogOutPanel from "./LogOutPanel";
import SettingsIcon from '@mui/icons-material/Settings';


const Menu = () => {
  const menuList = [
  // { label: 'Aswa', path: '/aswa' },
  // { label: 'Ekeng', path: '/ekeng' },
  { label: "Applications", path: "/applications", icon: <AppsIcon fontSize="small" /> },
  JSON.parse(localStorage.getItem('user'))?.role!==2?{
    label: "Settings",
    path: "/settings",
    icon: <SettingsIcon fontSize="small" />,
  }:{},
];
  const navigate = useNavigate();
  const { resetUser } = useContext(UserContext);
  const [openLogOutPanel, setOpenLogOutPanel] = useState(false);
  const handleOpenLogOutPanel = () => {
    setOpenLogOutPanel(!openLogOutPanel);
  };

  return (
    <Box
      className="menuOverlead"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <label
        orientation="vertical"
        variant="scrollable"
        aria-label="Vertical tabs example"
      ></label>
      <Box className="menuList">
        <Box className="navigateBar">
          {menuList.map((menuItem) => (
            <Link
              underline="none"
              key={menuItem.path}
              onClick={() => {
                navigate(menuItem.path);
              }}
            >
              <Button
                color={
                  menuItem.path === window.location.pathname
                    ? "inherit"
                    : "primary"
                }
                className="menuButtons"
              >
                <Box className="icon">{menuItem.icon}</Box>
                <Box className="link">{menuItem.label}</Box>
              </Button>
            </Link>
          ))}
        </Box>
        <footer className="navigateBar">
          <Button className="menuButtons" onClick={handleOpenLogOutPanel}>
            <Box className="icon">
              <LogoutIcon fontSize="small" />
            </Box>
            <Box className="link">logout</Box>
          </Button>
        </footer>
        {openLogOutPanel ? (
          <div className="log">
          <LogOutPanel
            handleOpenLogOutPanel={handleOpenLogOutPanel}
            resetUser={resetUser}
          /></div>
        ) : null}
      </Box>
    </Box>
  );
};

export default Menu;
