import React, { useEffect, useState } from "react";
import "./App.css";
import Login from "./pages/Auth/Login";
import Forgot from "./pages/Auth/Forgot";
import Confirm from "./pages/Auth/Confirm";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminWrapper from "./layouts/AdminWrapper";
import Aswa from "./pages/Aswa";
import Ekeng from "./pages/Ekeng";
import Users from "./pages/Users/Users";
import { UserContext } from "./context/UserContext";
import Register from "./pages/Auth/Register";
import User from "./pages/Users/User";
import { ToastContainer } from "react-toastify";
import PublicWrapper from "./layouts/PublicWrapper";
import Settings from "./pages/Settings/Settings"

function App() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : false;
    const access_token = localStorage.getItem("access_token");
    const userInfo = user
      ? {
          ...user,
          token: access_token,
        }
      : false;
    setUser(userInfo);
    setUser(user);
  }, []);
  const loginUser = ({ data }) => {
    localStorage.setItem("user", JSON.stringify(data.user));
    localStorage.setItem("access_token", data.access_token);
    setUser(data.user);
  };

  const resetUser = () => {
    setUser(false);
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
  };

  return (
    <UserContext.Provider value={{ user, setUser: loginUser, resetUser }}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <PublicWrapper>
                <Login />
              </PublicWrapper>
            }
          />
          <Route
            path="/register"
            element={
              <PublicWrapper>
                <Register />
              </PublicWrapper>
            }
          />
      
          <Route
            path="/login"
            element={
              <PublicWrapper>
                <Login />
              </PublicWrapper>
            }
          />
          <Route
            path="/confirm/:token"
            element={
              <PublicWrapper>
                <Confirm />
              </PublicWrapper>
            }
          />

          <Route
            path="/settings"
            element={
              <AdminWrapper>
                <Settings />
              </AdminWrapper>
            }
          />
          <Route
            path="/applications"
            element={
              <AdminWrapper>
                <Users />
              </AdminWrapper>
            }
          />
          <Route
            path="/applications/:id"
            element={
              <AdminWrapper>
                <User />
              </AdminWrapper>
            }
          />
          <Route path="*" element={<div>404 not found</div>} />
        </Routes>
      </Router>
      <ToastContainer />
    </UserContext.Provider>
  );
}

export default App;
