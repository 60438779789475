import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import "./Header.css";

const Header = ({ handleTogglePanel }) => (
  <Box>
    <AppBar className="header" position="static">
      <Toolbar>
        <IconButton
          className="menuIcon"
          onClick={handleTogglePanel}
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
        >
          <MenuIcon />
        </IconButton>
        <a href="#">
          <img
            alt="icon"
            className="headerIcon"
            src="/images/logo.png"
          ></img>
        </a>
      </Toolbar>
    </AppBar>
  </Box>
);
export default Header;
