import * as React from "react";
import Box from "@mui/material/Box";
import "./User.css";
import { Typography } from "@mui/material";
import BadgeIcon from "@mui/icons-material/Badge";
import LinkIcon from '@mui/icons-material/Link';
import DescriptionIcon from '@mui/icons-material/Description';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import EmailIcon from '@mui/icons-material/Email';
import DateRangeIcon from '@mui/icons-material/DateRange';
import WebIcon from '@mui/icons-material/Web';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import BusinessIcon from '@mui/icons-material/Business';
import ElevatorIcon from '@mui/icons-material/Elevator';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import SurroundSoundIcon from '@mui/icons-material/SurroundSound';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BoltIcon from '@mui/icons-material/Bolt';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import {Doughnut} from "react-chartjs-2";
import {Chart as ChartJS, ArcElement, plugins} from 'chart.js'
import { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import success from '../../images/success.png'
import danger from '../../images/danger.png'

ChartJS.register(
  ArcElement,
)
let industry = {
  1:'Fintech',
  2:'Healthcare',
  3:"Agriculture",
  4:'AI',
  5:'Deeptech',
  6:'Security',
  7:'Data Science',
  8:'Other'
}
export default function UserInfo(props) {
  let [data, setData] = useState(null);

  const score = props.currentScore?.totalScore
  const startColor = window.getComputedStyle(document.body, '').getPropertyValue('--grad-1');
  const endColor = window.getComputedStyle(document.body, '').getPropertyValue('--grad-2');

  const gradient = React.useMemo(() => {
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 900);
    const stopPercentage = score >= 250 ? score : 0;
    gradient.addColorStop(0, "#1998A0");
    gradient.addColorStop(stopPercentage / 1000, "#1998A0");
    gradient.addColorStop(stopPercentage / 2500, "#1998A0");
    gradient.addColorStop(1, "#1998A0");
    return gradient;
  }, [score]);

  useEffect(()=>{

    setData({
      labels:['yes','no'],
      datasets:[{
        cutout: '65%',
        label:"Poll",
        data:[Math.round(score),(500-Math.round(score))],
        backgroundColor: [gradient, "#ededed"],
        borderWidth:0,
        width: '10px'
        
      }],
    })
  },[score])
  console.log(JSON.parse("{\"decision\":\"yes\",\"minReqCode\":\"\",\"minReqMsg\":\"\",\"scale\":500,\"tier\":\"bronze\",\"scvv\":1,\"sccv\":1,\"scc\":7,\"evalutions\":[{\"title\":\"Founders and Team\",\"assessment\":\"Excellent\",\"value\":83.33333333333333,\"positiveMessages\":[\"CEO with 20 years of experience\",\"1 CEO exits\"],\"negativeMessages\":[],\"sectionDesc\":\"This section assesses your Founder's experience and exists, team structure, and skills.\"},{\"title\":\"Traction\",\"assessment\":\"Good\",\"value\":73.33333333333333,\"positiveMessages\":[\"18 months are sufficient for the round strategy\"],\"negativeMessages\":[],\"sectionDesc\":\"This section evaluates your company's financial state.\"},{\"title\":\"Scalability and Growth Potential\",\"assessment\":\"Average\",\"value\":55.333333333333336,\"positiveMessages\":[\" Great  Growth Rate in the next 12 months\"],\"negativeMessages\":[],\"sectionDesc\":\"This section evaluates your startup's revenue increase rates.\"},{\"title\":\"Competitive Advantage, Risks, Market Fit\",\"assessment\":\"Insufficient\",\"value\":14.666666666666666,\"positiveMessages\":[],\"negativeMessages\":[],\"sectionDesc\":\" This section assesses your startup's predictability of its exponential growth.\"},{\"title\":\"Next Generation Disruptive Product\",\"assessment\":\"Excellent\",\"value\":100,\"positiveMessages\":[\"The disruptiveness of your solutions are assessed good.\"],\"negativeMessages\":[],\"sectionDesc\":\"This section evaluates how innovative your startup is in the market. \"}],\"sv1\":125,\"sc1\":\"cexp5:ext2:eng3:\",\"sv2\":55,\"sc2\":\"tr2:rnw6:\",\"sv3\":83,\"sc3\":\"indna:bm1:nxrev3:3rev5:gr5:\",\"sv4\":11,\"sc4\":\"dfnd3:rtn1:creg1:fnd1:\",\"sv5\":50,\"sc5\":\"fst4:sts4:eas4:chp4:\",\"totalScore\":324,\"totalScoreRate\":6.48,\"genDescription\":\"Based on your Orion Score, the probability of your startup being funded in 6 months is 50%, and in 10 months it is 60%.\"}"))
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins:{
      legend:{
        display:false
      }
    },
  }
  
  const textCenter = {
    id:'textCenter',
    beforeDatasetsDraw(chart,args,pluginOptions){
      const {ctx,data} = chart;
      ctx.save();
      ctx.font = "bolder 30px sans-serif";
      ctx.fillStyle ="#1998A0";
      ctx.textBaseline = 'middle';
      ctx.fillText(`${Math.round(data.datasets[0].data[0])}`,chart.getDatasetMeta(0).data[0].x-52,chart.getDatasetMeta(0).data[0].y-2)
      ctx.fillStyle ="#ABAFB6";
      ctx.fillText(`/500`,chart.getDatasetMeta(0).data[0].x,chart.getDatasetMeta(0).data[0].y-2)
    }
  }
  const  formatNumberWithCommas = (number) => {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  console.log(data)
  console.log(props.currentScore)

  return (
    <div className="parentsInfo">
      <Box className="parentInfo">
        <Box>
          <Typography variant="h4" className="details">
            Application Details
          </Typography>
        </Box>
        <Box className="doughnutWrapper">
        <div className='cricle'>
        {data ? (<Doughnut
          style={{height:'290px',width:"290px",margin:'15px'}}
          data={data}
          options={options}
          plugins={[textCenter]}
          />) : null}
          </div>
         
         {props.currentScore?.evalutions?.map((i, key) => (
              <div key={key} className='info-wrapper1'>
                <div className='nameAndRanges'>
                  <div><h3 className='title'>{i.title}</h3></div>
                  <div className='Application-color'>
                    <h3 className='text-assessment'>{i.assessment}</h3>
                    <div className='chartText-wrapper'>{props.currentScore ? (
                      <><CircularProgressbar sx={{ width: "10%" }} value={Math.round(i.value)}
                        styles={buildStyles({
                          textSize: '20px',
                          pathColor: `${i.value < 45 ? 'red' : i.value > 45 && i.value < 75 ? 'orange' : 'green'}`,
                          textColor: `${i.value < 45 ? 'red' : i.value > 45 && i.value < 75 ? 'orange' : 'green'}`,
                          backgroundColor: 'red',
                        })}
                        text={Math.round(i.value) + " %"} /></>) : null}
                      <h4 className='sectionDesc'>{i.sectionDesc}</h4>
                    </div>
                  </div>
                </div>
                <div className='massages'>
                  {i?.positiveMessages.length ?
                    <div className='icons'>
                      <img src={success} width={15} />
                      <div><h3 className='text-title'>Things that you are great at.</h3>
                        {i?.positiveMessages.map((message, uid) => {
                          return <li key={uid} className='text'>{message}</li>
                        })}
                      </div>
                    </div> : null}
                  {i?.negativeMessages.length ?
                    <div className='icons'>
                      <img src={danger} width={15} />
                      <div><h3 className='text-title'>Things to focus on to make you more competitive.</h3>
                        {i?.negativeMessages.map((message, uid) => {
                          return <li key={uid} className='text'>{message}</li>
                        })}
                      </div>
                    </div>
                    : null}
                </div>
              </div>
            ))}
        
        <Box className="info">
          <Box className="childParent">
            <Box className="infoChilds">
              <Box className="userIcons">
                <BadgeIcon fontSize="small" />
                <Box className="infoName">First Name</Box>
              </Box>
              <Box className="id">{props.user?.founders?.map((founder)=>{
                return <span>{founder?.firstName}</span>
              })}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <EmailIcon fontSize="small" />
                <Box className="infoName">Email</Box>
              </Box>
              <Box className="id">
                {props.user?.founders?.length?props.user?.founders[0]?.email:''}
              </Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <LinkIcon fontSize="small" />
                <Box className="infoName">LinkedIn URL</Box>
              </Box>{" "}
              <Box className="id">{props.user?.founders?.length?props.user?.founders[0]?.linkedinUrl:''}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <BadgeIcon fontSize="small" />
                <Box className="infoName">Startup Name</Box>
              </Box>
              <Box className="id">{props.user?.startupName}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <DateRangeIcon fontSize="small" />
                <Box className="infoName">Year Founded</Box>
              </Box>
              <Box className="id">{props.user?.yearFounded}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <WebIcon fontSize="small" />
                <Box className="infoName">Website</Box>
              </Box>
              <Box className="id">{props.user?.website}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <LinkedInIcon fontSize="small" />
                <Box className="infoName">LinkedIn</Box>
              </Box>
              <Box className="id">{props.user?.linkedin}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <VideoSettingsIcon fontSize="small" />
                <Box className="infoName">Demo Video URL</Box>
              </Box>
              <Box className="id">{props.user?.demoVideoUrl}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <WarehouseIcon fontSize="small" />
                <Box className="infoName">Industry</Box>
              </Box>
              <Box className="id">{industry[props.user?.industry]}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <LocationOnIcon fontSize="small" />
                <Box className="infoName">HQ City Location</Box>
              </Box>
              <Box className="id">{props.user?.hqLocationName}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <CorporateFareIcon fontSize="small" />
                <Box className="infoName">Incorporated</Box>
              </Box>
              <Box className="id">{props.user?.incorporated===0?"No":"Yes"}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <ElevatorIcon fontSize="small" />
                <Box className="infoName"> Level of Experience</Box>
              </Box>
              <Box className="id">{formatNumberWithCommas(props.user?.levelEx)}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <FormatListNumberedIcon fontSize="small" />
                <Box className="infoName">Number Of CEO Exists</Box>
              </Box>
              <Box className="id">{formatNumberWithCommas(props.user?.numberOfCEOExists)}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <FormatListNumberedIcon fontSize="small" />
                <Box className="infoName">Number Of Engineers</Box>
              </Box>
              <Box className="id">{formatNumberWithCommas(props.user?.numberOfEngineers)}</Box>
            </Box>
          </Box>
          <Box className="childParent">

            <Box className="infoChilds">
              <Box className="userIcons">
                <BusinessIcon fontSize="small" />
                <Box className="infoName">Business Model</Box>
              </Box>
              <Box className="id">{formatNumberWithCommas(props.user?.businessModel)}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <ElevatorIcon fontSize="small" />
                <Box className="infoName">Client Retention Rate</Box>
              </Box>
              <Box className="id">{formatNumberWithCommas(props.user?.clientRetention)}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <ContentPasteSearchIcon fontSize="small" />
                <Box className="infoName">IP Registered</Box>
              </Box>
              <Box className="id">{props.user?.ipRegistered===1?"Yes":'No'}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <SurroundSoundIcon fontSize="small" />
                <Box className="infoName">Competitive Advantage</Box>
              </Box>
              <Box className="id">{props.user?.competitiveAdvantage}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <FormatListNumberedIcon fontSize="small" />
                <Box className="infoName">Describe Your Management</Box>
              </Box>
              <Box className="id">{props.user?.describeManagement}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <CalendarMonthIcon fontSize="small" />
                <Box className="infoName">Planned Revenue in 1 Year</Box>
              </Box>
              <Box className="id">{formatNumberWithCommas(props.user?.plannedRevenueInYear)}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <CalendarMonthIcon fontSize="small" />
                <Box className="infoName">Planned Revenue in the Last 12 Months</Box>
              </Box>
              <Box className="id">{formatNumberWithCommas(props.user?.plannedRevenueLast12Months)}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <CalendarMonthIcon fontSize="small" />
                <Box className="infoName">Planned Revenue in Three Years</Box>
              </Box>
              <Box className="id">{formatNumberWithCommas(props.user?.plannedRevenueInThreeYear)}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <CallReceivedIcon fontSize="small" />
                <Box className="infoName">Funds Received</Box>
              </Box>
              <Box className="id">{formatNumberWithCommas(props.user?.fundsReceived)}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <CalendarTodayIcon fontSize="small" />
                <Box className="infoName">Runway Months</Box>
              </Box>
              <Box className="id">{formatNumberWithCommas(props.user?.runawayMonths)}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <AttachMoneyIcon fontSize="small" />
                <Box className="infoName">Desired Amount To Raise</Box>
              </Box>
              <Box className="id">{formatNumberWithCommas(props.user?.desiredAmountToRaise)}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <CurrencyExchangeIcon fontSize="small" />
                <Box className="infoName">Budget Friendly</Box>
              </Box>
              <Box className="id">{formatNumberWithCommas(props.user?.budget)}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <AutoAwesomeIcon fontSize="small" />
                <Box className="infoName">Easier</Box>
              </Box>
              <Box className="id">{props.user?.easier}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <BoltIcon fontSize="small" />
                <Box className="infoName">Faster</Box>
              </Box>
              <Box className="id">{props.user?.fast}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <FactCheckIcon fontSize="small" />
                <Box className="infoName">Satisfactory</Box>
              </Box>
              <Box className="id">{props.user?.satisfactory}</Box>
            </Box>
            <Box className="infoChilds">
              <Box className="userIcons">
                <DescriptionIcon fontSize="small" />
                <Box className="infoName">Product Description</Box>
              </Box>
              <Box className="id">{props.user?.productDescription}</Box>
            </Box>
          </Box>
        </Box>
        </Box>
      </Box>
    </div>
  );
}
