import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import "./User.css";
import { useParams } from "react-router-dom";
import api from "../../utils/api";
import { toast } from "react-toastify";
import UserInfo from "./UserInfo";
import DeleteRow from "../../parts/DeleteRow";

export default function TemplatesModal() {
  const [user, setUser] = useState();
  const { id } = useParams();
  const [currentScore, setCurrentScore] = useState(null)

  useEffect(() => {
    api.get(`/applications/${id}/admin`)
      .then((applications) => {
        console.log(applications)
        setUser(applications);
        setCurrentScore(JSON.parse(applications.dms))
      })
      .catch(console.log);
  },[id])
console.log(currentScore)
  return (
    <div>
      <Box className="infoParentDiv">
        <Box className="userInfo">
          <UserInfo currentScore={currentScore} user={user} />
        </Box>
        <Box className="carInfo">
        </Box>
      </Box>
      <Box className="addContactsParent">
        <Box className="addContactsChild">
        </Box>
        <Box className="addContactsChild">
        </Box>
      </Box>
    </div>
  );
}
