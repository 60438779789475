import * as React from 'react';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Table from '../../parts/Table/Table';
import api from "../../utils/api";
import { toast } from 'react-toastify';
import SettingsModal from './SettingsModal';
import './Settings.css'
import DeleteRow from '../../parts/DeleteRow';
import { useParams } from 'react-router-dom';
import TocIcon from '@mui/icons-material/Toc';
import SettingsIcon from '@mui/icons-material/Settings';
import { SETTING_TYPE_ENUM } from './SettingTypes';
import { render } from '@testing-library/react';
import SettingsActions from './SettingsActions';

const _settingCells = [
    {
        field: 'id',
        label: 'ID',
        filter: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: true,
    },
    {
        field: 'name',
        label: 'Name'
    },
    {
        field: 'url',
        label: 'URL',

    },
    {
        field: 'type',
        label: 'Type',
        cellRenderer: ({ data: { type } }) => SETTING_TYPE_ENUM[type]
    },
];

const Settings = React.memo(() => {
    const [settingCells, setSettingCells] = useState([])
    const [isOpenNodeCreate, setIsOpenNodeCreate] = useState(false)
    const [isOpenNodeEdit, setIsOpenNodeEdit] = useState(false)
    const [settingRows, setSettingRows] = useState([])
    const [selectedRows, setSelectedRows] = useState({});
    const [isValidName] = useState(true)
    const [isValidType] = useState(true)
    const [settingRow, setSettingRow] = useState({})

    const handleCreateNodePanel = () => {
        setIsOpenNodeCreate(!isOpenNodeCreate)
    }

    const handleEditNodePanel = ({ data: _row }) => {
        setIsOpenNodeEdit(!isOpenNodeEdit)
        setIsOpenNodeCreate(false)
        setSettingRow(_row)
    }

    const fetchSettings = (cb) => {
        api.get('/settings').then((settings) => {
            setSettingRows(settings);
            cb && cb()
        }).catch(console.log)
    }

    useEffect(() => {
        fetchSettings();
        setSettingCells(_settingCells)
    }, [])

    const handleCreate = (event) => {
        event.preventDefault()
        const { target: { url: urlValue, reqType: reqTypeValue, name: nameValue, key: keyValue, type: typeValue,
            username: usernameValue, password: passwordValue, loginAction: loginActionValue, getDataAction: getDataActionValue, } } = event


        const url = urlValue.value;
        const name = nameValue.value;
        const type = parseInt(typeValue.value);

        let body = {
            url,
            name,
            type
        }
        if (type == 1) {
            const key = keyValue.value;
            body = {
                key,
                url,
                name,
                type
            }
        } else if (type == 2) {
            const username = usernameValue.value;
            const password = passwordValue.value;
            const reqType = reqTypeValue.value;
            body.additionalData = {
                username,
                password,
                reqType,
                url,
                name,
            }
        } else {
            const username = usernameValue.value;
            const password = passwordValue.value;
            const loginAction = loginActionValue.value;
            const getDataAction = getDataActionValue.value;
            body.additionalData = {
                username,
                password,
                loginAction,
                getDataAction,
                url,
                name,
            }
        }

        if (isValidName && isValidType) {

            api.post('/settings', body).then(() => {
                fetchSettings()
                handleCreateNodePanel()
                toast.success("Succes!")
            }).catch(() => {
                toast.error("Error!");
            })
        }
    }

    const handleNodeEdit = (event) => {
        event.preventDefault()
        const { target: { url: urlValue, reqType: reqTypeValue, name: nameValue, key: keyValue, type: typeValue,
            username: usernameValue, password: passwordValue, loginAction: loginActionValue, getDataAction: getDataActionValue } } = event

        const url = urlValue.value;
        const name = nameValue.value;
        const type = parseInt(typeValue.value);

        let body = {
            url,
            name,
            type
        }

        if (type == 1) {
            const key = keyValue.value;
            body = {
                key,
                url,
                name,
                type
            }
        } else if (type == 2) {
            const username = usernameValue.value;
            const password = passwordValue.value;
            const reqType = reqTypeValue.value;
            body.additionalData = {
                username,
                password,
                reqType,
                url,
                name
            }
        } else {
            const username = usernameValue.value;
            const password = passwordValue.value;
            const loginAction = loginActionValue.value;
            const getDataAction = getDataActionValue.value;
            body.additionalData = {
                username,
                password,
                loginAction,
                getDataAction,
                url,
                name
            }
        }

        if (isValidName && isValidType) {
            api.put(`/settings/${settingRow.id}`, body, body.additionalData).then(() => {
                fetchSettings()
                setIsOpenNodeEdit(!isOpenNodeEdit)
                toast.success("Succes!")
            }).catch((E) => {
                console.log(E);
                toast.error("Error!");
            })
        }
    }

    return <Box className='cars'>
        <Box className='import'>
            <Box className='importIcon'><SettingsIcon fontSize='large' /></Box>
            <Box className='importName'>
                <Typography variant='h5'>Add Settings</Typography>
            </Box>
        </Box>
        <Box className='typesWrapper'>
            <Box className='contactWrapper'>
                <Box className='typeWrapper'>
                    <Typography className='typeText'>Settings</Typography>
                </Box>
                <SettingsActions 
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                cars={settingRows}
                setCars={setSettingRows}
                path={'settings'}/>
                <Button color='inherit' className='createButton' onClick={handleCreateNodePanel} variant='outlined'
                >Add
                </Button>
                {isOpenNodeCreate ? <SettingsModal
                    title="Add Setting"
                    create={handleCreateNodePanel}
                    submit={handleCreate}
                    button="Add"
                    settingRow={settingRow}
                /> : null}
                {isOpenNodeEdit ? <SettingsModal
                    title="Edit Setting"
                    create={handleEditNodePanel}
                    submit={handleNodeEdit}
                    name={settingRow.url}
                    button="Edit"
                    isEditable={true}
                    _settingCells={_settingCells}
                    settingRow={settingRow}
                /> : null}

                <Table className='contactTable'
                    showCheckBox={false}
                    rows={settingRows}
                    cells={settingCells}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    isConfigurable={false}
                    rowClick={handleEditNodePanel}
                    id='contacts_1'
                />
            </Box>
        </Box>
    </Box>
});

export default Settings;
