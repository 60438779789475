import * as React from "react";
import { Fragment, useEffect, useState, useCallback, useParams } from "react";
import Box from "@mui/material/Box";
import api from "../../utils/api";
import Table from "../../parts/Table/Table";
import { json, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import "./User.css";
import {formatDate} from './../../../src/utils/dateFormat'
import DeleteRow from "../../parts/DeleteRow";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import AppsIcon from '@mui/icons-material/Apps';
import SettingsActions from "../Settings/SettingsActions";

const industries = [
  { label: 'Fintech', id: "1" },
  { label: 'Healthcare', id: "2" },
  { label: 'Agriculture', id: "3"},
  { label: 'AI', id: "4"},
  { label: 'Deeptech', id: "5"},
  { label: 'Security', id: "6"},
  { label: 'Data Science', id: "7"},
  { label: 'Other', id: "8"},
]



export default function Users() {
  const headCells = [
    {
      field: "id",
      label: "ID",
      maxWidth: 100,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.id
      },
      filter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: true,
    },
     
    {
      field: "startupName",
      label: "StartUp Name",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.startupName
      },
    },
  
    {
      field: "createdAt",
      label: "Date",
      filter: true,
      sortable: true,
      valueGetter: (params) => {
        return formatDate(new Date(params.data.createdAt))
    },
    },
  
  
    {
      field: "yearFounded",
      label: "Year Founded",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.yearFounded
      },
    },
  
    {
      field: "website",
      label: "Website",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.website
      },
    },
  
    {
      headerName: "Score",
      label: 'dms',
      filter: true,
      sortable: true,
      cellRenderer : ({ data }) => {
        try {
          const parsedData = JSON.parse(data?.dms);
          return parsedData?.totalScore;
        } catch (error) {

          return null; 
        }
      },
    },
  
    {
      field: "demoVideoUrl",
      label: "Demo Video Url",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.website
      },
    },
  
    {
      field: "industry",
      label: "Industry",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return industries.find((i)=>i.id == additionalData?.industry)?.label
      },
    },
  
    {
      field: "businessModel",
      label: "Business Model",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.businessModel
      },
    },
  
    {
      field: "fundingStage",
      label: "Funding Stage",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.fundingStage
      },
    },
  
    {
      field: "ipRegistered",
      label: "IP Registered",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.ipRegistered
      },
    },
  
    {
      field: "roundsRaised",
      label: "Rounds Raised",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.roundsRaised
      },
    },
  
    {
      field: "currentValueEstimate",
      label: "Current Value",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.currentValueEstimate
      },
    },
  
    {
      field: "plannedRevenueInYear",
      label: "Revenue In Year",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.plannedRevenueInYear
      },
    },
  
    {
      field: "plannedRevenueInTwoYear",
      label: "Revenue In Two Year",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.plannedRevenueInTwoYear
      },
    },
  
    {
      field: "plannedRevenueInThreeYear",
      label: "Revenue In Three Year",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.plannedRevenueInThreeYear
      },
    },
  
    {
      field: "fundsReceived",
      label: "Funds Received",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.fundsReceived
      },
    },
  
    {
      field: "runawayMonths",
      label: "Run Away Months",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.runawayMonths
      },
    },
  
    {
      field: "desiredAmountToRaise",
      label: "Desired Amount To Raise",
      filter: true,
      sortable: true,
      cellRenderer: ({ data: additionalData }) => {
        return additionalData.desiredAmountToRaise
      },
    },
  
  ];
  
  const [cells, setCells] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenPanel, setIsOpenPanel] = useState(false);
  const navigate = useNavigate();

  const fetchApplications = (cb) => {
    api.get('/applications').then((applications) => {
      console.log(applications)
      setRows(applications.applications);
      cb && cb()
    }).catch(console.log)
  }

  useEffect(() => {
    fetchApplications();
    setCells(headCells);
  
  }, []);

  const handleOpenPanel = ({ data: row }) => {
    setIsOpenPanel(!isOpenPanel);
    navigate(`/applications/${row.id}`);
  };

  return (
    <Box className="users">
    
      <Box className='typesWrapper'>
        <Box className='contactWrapper'>
        
          <SettingsActions
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            cars={rows}
            setCars={setRows}
            path='applications'
          />
          <Table
            rows={rows}
            cells={cells}
            showCheckBox={false}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            rowClick={handleOpenPanel}
            searchType={headCells.searchType}
            id="users"
            onFilterChange={fetchApplications}
          />
        </Box>
      </Box>
    </Box>
  );
}
