import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import "./Settings.css";
import api from "../../utils/api";
import { Sync } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SettingsActions = ({
  selectedRows,
  setSelectedRows,
  cars,
  setCars,
    path
}) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const openDeletePanel = () => {
    setOpen(!isOpen);
  };

  const handleDelete = () => {
    setLoading(true);
    const ids = cars
      .filter((c) => selectedRows.includes(c.id))
      .map((c) => c.id);
    api
      .post(`${path}/delete`, {
         ids,
      })
      .then(() => {
        const newCarsList = cars.filter((c) => !selectedRows.includes(c.id));
        setCars(newCarsList);
        setSelectedRows([]);
        toast.success("Deleted successfully");
        setOpen(false)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box className={`CarsActions ${selectedRows.length ? "show" : ""}`}>
      <Box className="buttonParentDiv">
        {loading ? <Sync className="rotate" /> : null}
        <Box className="firstButtonDiv">
          <Button
            disabled={loading}
            className="applyButton"
            variant="contained"
            color="error"
            size="small"
            onClick={openDeletePanel}
          >
            Delete
          </Button>
          {isOpen ? (
            <Box className="overlead">
              <Box className="logOutForm" sx={{ borderRadius: "5px" }}>
                <Typography>
                  Are you sure, you want to delete this item?
                </Typography>
                <Box className="LogOutPanelButtons">
                  <Button
                    color="error"
                    onClick={handleDelete}
                    sx={{ width: "25%", margin: "20px" }}
                    variant="contained"
                  >
                    Yes
                  </Button>
                  <Button
                    color="inherit"
                    type="submit"
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpen(!isOpen);
                    }}
                    sx={{ color: "black", width: "25%", margin: "20px" }}
                    variant="contained"
                  >
                    No
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
export default SettingsActions;
